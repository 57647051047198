import '../style/style.css'
import React from 'react'
import Images from '../assets/images/images'
import Element from '../style/screens/metadata_screen_styles'

const MetadataScreen = () => {
  return (
    <Element>
      <Element.header>
        <Element.header.logo src={Images.rosieLogoColor}/>
        <Element.header.right>
          <Element.header.home src={Images.homeBlueBtn}/>
          <Element.header.profile src={Images.avatar}/>
        </Element.header.right>
      </Element.header>

      <Element.form>
        <Element.title>Add some details to your item</Element.title>
        <Element.subtitle>
          We’ve got you started with some of the information we could gather.
        </Element.subtitle>

        <Element.photo>
          <Element.pic src={Images.itemDetails}/>
          <Element.picEdit src={Images.edit}/>
        </Element.photo>

        <img src={Images.mdName}/>
        <img src={Images.suggest}/>
        <Element.mdSelect>
          <option>Silver</option>
        </Element.mdSelect>
        <Element.mdSelect>
          <option>200lb</option>
        </Element.mdSelect>
        <Element.mdRow>
          <Element.locationBtn src={Images.kitchenBtn}/>
          <Element.locationBtn src={Images.laundryBtn}/>
          <Element.locationBtn src={Images.garageBtn}/>
        </Element.mdRow>
        <img src={Images.addDesc}/>
        <div>Where is this located?</div>
        <Element.mdSelect>
          <option>Home</option>
        </Element.mdSelect>
        <Element.btnDark type='button'>
          <Element.btnContent>
            <span>Add to Inventory</span>
          </Element.btnContent>
        </Element.btnDark>

      </Element.form>
    </Element>
  )
}

export default MetadataScreen
