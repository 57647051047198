import '../style/style.css'
import React, {useState} from 'react'
import Modal from 'react-modal'
import Images from '../assets/images/images'
import Element from '../style/screens/dashboard_screen_styles'

const DashboardScreen = ({ navigate }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <Element>
      <Element.header>
        <Element.header.logo src={Images.rosieLogoColor} />
        <Element.header.right>
          <Element.header.identify src={Images.iconRoundColorWhiteBG} onClick={() => navigate('identify')}/>
          <Element.header.profile src={Images.avatar}/>
        </Element.header.right>
      </Element.header>
      <Element.form>
        <Element.title>Your inventory at</Element.title>
        {/*
        <select className='d-select'>
          <option>Home</option>
          <option>Office</option>
          <option>RV Camper</option>
          <option>Storage Unit</option>
          <option>Camper</option>
        </select>
        */}
        <Element.homeSelect
          src={Images.homeSelect}
          onClick={() => {
            setModalIsOpen(true)
          }}
        />

        <Element.tabs>
          <Element.tab>
            <Element.tabLink href='#' active aria-current='page'>
              Spaces
            </Element.tabLink>
          </Element.tab>
          <Element.tab>
            <Element.tabInactive href='#'>
              Assets
            </Element.tabInactive>
          </Element.tab>
        </Element.tabs>

        <Element.grid>
          <Element.grid.item src={Images.iLaundry}/>
          <Element.grid.item src={Images.iGarage}/>
          <Element.grid.item src={Images.iKitchen}/>
          <Element.grid.item src={Images.iMBedroom}/>
          <Element.grid.item src={Images.iBathroom}/>
          <Element.grid.item src={Images.iBackYard}/>
        </Element.grid>

      </Element.form>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel='Image Data'
        overlayClassName='i-modal-overlay'
        className='d-modal'
      >
        <Element.modalContent>
          <Element.modalHeader>
            <Element.modalLogo src={Images.rosieLogoColor}/>
            <Element.modalHeaderRight>
              <Element.modalProfile src={Images.closeBtn} onClick={() => setModalIsOpen(false)}/>
            </Element.modalHeaderRight>
          </Element.modalHeader>
          <Element.modalForm>
            <img src={Images.homeListing}/>
          </Element.modalForm>
          <Element.listing>Office</Element.listing>
          <Element.listing>RV Camper</Element.listing>
          <Element.listing>Storage Unit</Element.listing>
          <Element.listing>Rental</Element.listing>
        </Element.modalContent>
      </Modal>
    </Element>
  )
}

export default DashboardScreen
