import '../style/style.css'
import React from 'react'
import Images from '../assets/images/images'
import Element from '../style/screens/thumb_select_screen_styles'

const ThumbSelectScreen = () => {
  return (
    <Element>
      <Element.header>
        <Element.header.logo src={Images.rosieLogoColor} />
        <Element.header.right>
          <Element.header.home src={Images.homeBlueBtn} />
          <Element.header.profile src={Images.avatar} />
        </Element.header.right>
      </Element.header>

      <Element.form>
        <Element.title>Select a thumbnail for your item</Element.title>
        <Element.subtitle>
          Choose an image to represent this item within your inventory. You can change it later, too.
        </Element.subtitle>

        <Element.photo>
          <Element.thumb src={Images.itemCheckPhoto}/>
        </Element.photo>

        <Element.btnDark type='button'>
          <Element.btnContent>
            <span>Select Photo</span>
          </Element.btnContent>
        </Element.btnDark>
        <Element.btnLight type='button'>
          Choose New Photo
        </Element.btnLight>

      </Element.form>
    </Element>
  )
}

export default ThumbSelectScreen
