async function postImageDataApi(data) {
  try {
    const endpointurl = "/api/postscanimagedata";

    // const data = await convertImageToRGBArray(_file);

    const res = await fetch(endpointurl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer CSp0Cb97PV7L4UT6ET2G3gr27RV1FYyE",
      },
      body: JSON.stringify(data),
    });
    const metadatabody = await res.json();
    if (res) {
      return {
        success: true,
        message: "Location added successfully",
        data: metadatabody,
      };
    } else {
      throw new Error("Failed to add location");
    }
  } catch (e) {
    console.error("Error in handleSubmit:", e);
    return { success: false, message: `${e}` };
  }
}

async function getImageIdApi(data) {
  try {
    const endpointurl = "/api/getscanimageid";

    const res = await fetch(endpointurl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer CSp0Cb97PV7L4UT6ET2G3gr27RV1FYyE",
      },
      body: JSON.stringify(data),
    });
    const metadatabody = await res.json();
    // Check if the insertion was successful and return a success response
    if (res) {
      return {
        success: true,
        message: "Location added successfully",
        data: metadatabody,
      };
    } else {
      throw new Error("Failed to add location");
    }
  } catch (e) {
    console.error("Error in handleSubmit:", e);
    return { success: false, message: `${e}` };
  }
}

export { postImageDataApi, getImageIdApi };
