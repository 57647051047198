import './App.css';
import './style/style.css'
import {useState} from "react";
import Splash from "./screens/SplashScreen";
import Login from "./screens/LoginScreen";
import Identify from "./screens/IdentifyScreen";
import ItemCheck from "./screens/ItemCheckScreen";
import ThumbSelect from "./screens/ThumbSelectScreen";
import Metadata from "./screens/MetadataScreen";
import Dashboard from "./screens/DashboardScreen";
import Location from "./screens/LocationScreen";

function App() {

  const navigate = (target) => {
    console.log("Navigating to", target);
    setScreen(target)
  }

  const [screen, setScreen] = useState("splash");

  switch(screen) {
    case "splash":
      return <Splash navigate={navigate} />;
    case "login":
      return <Login navigate={navigate} />;
    case "identify":
      return <Identify />;
    case "item_check":
      return <ItemCheck />;
    case "thumb_select":
      return <ThumbSelect />;
    case "metadata":
      return <Metadata />;
    case "dashboard":
      return <Dashboard navigate={navigate}/>;
    case "location":
      return <Location />;
    default:
      return <Login navigate={navigate}/>;
  }
}

export default App;
