import styled from 'styled-components'
import { Colors } from '../../constants/colors'

const Element = styled.div`
  min-height: 100vh;
 `
Element.header = styled.div`
  display: flex;
  justify-content: space-between;
`
Element.header.logo = styled.img`
`
Element.header.right = styled.div`
  display: flex;
  gap: 10px;
`
Element.header.home = styled.img`
  height: 40px;
  width: 40px;
`
Element.header.profile = styled.img`
  height: 38px;
  width: 38px;
`
Element.subtitle = styled.div`
  text-align: center;
  color: #E6E6E6;
  font-size: 16px;
  line-height: 24px;
  padding-left: 40px;
  padding-right: 40px;
`
Element.rosieBtn = styled.img`
  position: absolute;
  height: 80px;
  width: 80px;
  margin-top: 187px;
  margin-left: 133px;
  z-index: 99;
`
Element.rosieBarcodeBtn = styled.img`
  position: absolute;
  height: 80px;
  width: 80px;
  margin-top: 270px;
  margin-left: 133px;
  z-index: 99;
`
Element.progressContainer = styled.div`
  height: 80px;
  width: 80px;
  margin-top: 190px;
  margin-left: 133px;
  position: relative;
`
Element.topLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 20;
`
Element.progressCircle = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`
Element.progressCircleIndicator = styled.img`
  position: absolute;
  top: 28px;
  left: 28px;
`
Element.progressCircleRing = styled.img`
  position: absolute;
  top: 60px;
  left: 5px;
`
Element.dots = styled.img`
  position: absolute;
  top: 295px;
  z-index: 0;
`
Element.footer = styled.div`
  position: fixed;
  bottom: 0;
  right: 2px;
  padding: 25px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`
Element.console = styled.div`
  position: fixed;
  color: white;
  bottom: 0;
  right: 15px;
  padding-left: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`
Element.modalContent = styled.div`
  padding: 20px;
  width: 340px;
`
Element.form = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`
Element.modalTitle = styled.div`
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  margin-top: 10px;
  color: #221F20;
`
Element.modalSubtitle = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
  margin-left: 3px;
  margin-right: 3px;
  color: #413E3F;
  text-align: center;
`
Element.btnDark = styled.button`
  background: #483EE7;
  border-radius: 8px;
  color: white;
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 10px;
  width: 335px;
`

Element.btnContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

Element.btnLight = styled.button`
  background: white;
  width: 335px;
  border: 1px solid #57BBF8;
  border-radius: 8px;
  color: #221F20;
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
`

export default Element