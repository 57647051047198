// eslint-disable-next-line import/prefer-default-export
export const Colors = {
  charcoal: '#323031',
  charcoalGrey: '#414042',
  darkGreyBackground: '#231f20',
  darkText: '#000000',
  errorText: '#d0021b',
  facebookBlueBackground: '#32599d',
  googleRedBackground: '#fa3535',
  gray: '#808080',
  grayButtonBackground: '#f5f5f5',
  grayButtonText: '#9b9b9b',
  grayText: '#808080',
  lightGray: '#eeeeee',
  listItemSeparatorColor: '#c3c3c3',
  purpleBrown: '#231f20',
  red: '#FF0000',
  safeAreaBackground: '#000000',
  stravaBackground: '#ff3700',
  textInput: '#8e8e93',
  transparentBlack: 'rgba(0,0,0,0.5)',
  trainingPeaksBackground: '#557fa9',
  twitterBlueBackground: '#00abe5',
  white: '#ffffff',

  cardBorderColor: 'rgba(206, 209, 217, 0.24)',
  cardShadow: '0 12px 16px 0 rgba(50, 62, 99, 0.03)',
  battleshipGrey: 'rgb(112, 118, 137)',
  black: 'rgb(29, 33, 41)',
  inputPlaceholderColor: 'rgb(154, 162, 189)',
  textInputBorderColor: 'rgb(206, 209, 217)',
  azure: 'rgb(2, 158, 226)',
  errorColor: 'rgb(237, 30, 122)',
  dividerColor: '#e0e0e0',
  textInputDisabledBackground: 'rgb(247, 247, 250)',
  buttonDisabledBackground: 'rgb(206, 209, 217)',
}
