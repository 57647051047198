// app/upload/uploadImageAction.ts

// import { eq } from "drizzle-orm";

// import { uploadImageToBlob } from "@/lib/azureblob";
// import { db } from "@/lib/db";
// import { assets, image, location } from "../../../db";

export async function uploadImageAction(base64Image, fileName, userid) {
  return null
}
/*
  // Convert Base64 string to Buffer
  const imageBuffer = Buffer.from(base64Image.split(",")[1], "base64"); // Remove the Base64 header
  console.log(fileName);
  console.log("userid", userid);
  const imageUrl = await uploadImageToBlob(
    `mluploadedimages`,
    `${userid}_${fileName}`,
    imageBuffer
  );
  console.log(imageUrl);

  try {
    const [firstLocation] = await db
      .select()
      .from(location)
      .where(eq(location.ownerId, userid))
      .limit(1);

    if (!firstLocation) {
      throw new Error("No location found for the given ownerId");
    }

    const locationId = firstLocation.locationId;

    // Insert a dummy asset
    const [newAsset] = await db
      .insert(assets)
      .values({
        location: locationId,
        name: "Dummy Asset",
        images: {},
        resizedimages: {},
        thumbnail: "dummy_thumbnail",
        color: "red",
        brand: "dummy_brand",
        height: 100,
        width: 50,
        model: "dummy_model",
        warranty: "1 year",
        category: "dummy_category",
        weight: 10,
        price: 100,
        controls: "dummy_controls",
        connectivity: "dummy_connectivity",
        skucode: "dummy_skucode",
        barcode: "dummy_barcode",
      })
      .returning();

    const assetId = newAsset.assetId;
    // Insert a dummy image
    await db.insert(image).values({
      assetId: assetId,
      originalImageUrl: imageUrl,
      resizedImageUrl: imageUrl,
      inferenceData: {},
      timestamp: new Date(),
      inferenceTimestamp: new Date(),
      status: "new",
    });
  } catch (error) {
    console.log(error);
  }

  return imageUrl; // Return the URL of the uploaded image
}
 */
