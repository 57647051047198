import styled from 'styled-components'

const Element = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url('../../assets/images/gradient.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
Element.header = styled.div`
  padding-top: 30px;
  padding-bottom: 40px;
`

export default Element