import React, { useEffect } from "react";
import "../style/style.css";
import SplashScreen from "../style/screens/splash_screen_styles";
import { ReactComponent as RosieIcon } from "../assets/svg/rosie_icon.svg";

const SplashScreenStyled = ({ navigate }) => {
  useEffect(() => {
    setTimeout(() => {
      navigate("dashboard");
    }, 500);
  }, [navigate]);

  return (
    <div className="splash">
      <SplashScreen.rosieIcon>
        <RosieIcon />
      </SplashScreen.rosieIcon>
    </div>
  );
};

export default SplashScreenStyled;
