import '../style/style.css'
import React from 'react'
import Images from '../assets/images/images'
import Element from '../style/screens/item_check_screen_styles'

const ItemCheckScreen = () => {
  return (
    <Element>
      <Element.header>
        <Element.header.logo src={Images.rosieLogoColor}/>
        <Element.header.right>
          <Element.header.home src={Images.homeBlueBtn}/>
          <Element.header.profile src={Images.avatar}/>
        </Element.header.right>
      </Element.header>

      <Element.form>
        <Element.photo>
          <Element.photoBG src={Images.itemCheckBG}/>
          <Element.photoImage src={Images.itemCheckPhoto}/>
        </Element.photo>
        <Element.title>
          We need some more information
        </Element.title>
        <Element.subtitle>
          Please scan the barcode or provide the model number to begin.
        </Element.subtitle>

        <Element.btnDark type='button'>
          <Element.btnContent>
            <img src={Images.cameraIcon}/>
            <span>Scan Info</span>
          </Element.btnContent>
        </Element.btnDark>
        <Element.btnLight type='button'>
          Enter model number
        </Element.btnLight>

      </Element.form>
    </Element>
  )
}

export default ItemCheckScreen
