import styled from 'styled-components'

const Element = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-image: url('../../assets/images/gradient.png');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`

Element.rosieIcon = styled.div`
  height: 100px;
`

export default Element