import styled from 'styled-components'
import { Colors } from '../../constants/colors'

const Element = styled.div`
  min-height: 100vh;
  padding: 20px;
  background: linear-gradient(0deg, #D1EDFF, #FFFFFF), linear-gradient(167.99deg, #FFFFFF 0%, #D1EDFF 100%);
`
Element.header = styled.div`
  display: flex;
  justify-content: space-between;
`
Element.header.logo = styled.img`
`
Element.header.right = styled.div`
  display: flex;
  gap: 10px;
`
Element.header.identify = styled.img`
  height: 40px;
  width: 40px;
`
Element.header.profile = styled.img`
  height: 38px;
  width: 38px;
`
Element.form = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
Element.title = styled.div`
  margin-top: 30px;
  width: 100%;
  font-size: 36px;
  line-height: 39px;
  color: #221F20;
`
Element.homeSelect = styled.img`
  margin-right: auto;
  height: 40px;
  object-fit: contain;
`
Element.grid = styled.div`
  display: grid;
  grid-template-columns: 170px 170px;
`
Element.grid.item = styled.img`
  padding: 5px;
`
Element.listing = styled.div`
  margin-top: 30px;
  font-size: 36px;
  line-height: 39px;
  padding-left: 5px;
  padding-right: 20px;
  color: #221F20;
`
Element.tabs = styled.ul`
  display: flex;
  font-size: 16px;     
  line-height: 20px;
  font-weight: 500;
  color: ${Colors.grayText};
  background: #D4E9F5;
  margin-top: 30px;
  margin-left: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 30px;
  list-style-type: none;
`
Element.tab = styled.li`
`
Element.tabLink = styled.a`
  display: inline-block;
  padding-top: 8px;     
  padding-bottom: 8px;
  margin-left: -30px;
  font-size: 16px;
  color: white;
  background-color: rgb(37 99 235);
  border-radius: 20px;
  text-decoration: none;
  font-weight: 800;
  text-align: center;
  width: 130px;
`
Element.tabInactive = styled.a`
  display: inline-block;
  padding-left: 4px;     
  padding-right: 4px;
  padding-top: 8px;     
  padding-bottom: 8px;
  border-radius: 8px;
  font-weight: 800;
  text-decoration: none;
  text-align: center;
  width: 130px;
`
Element.modalContent = styled.div`
  height: 100%;
  padding: 20px;
  background: linear-gradient(167.99deg, #D1EDFF 0%, #FFFFFF 100%);
`
Element.modalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`
Element.modalLogo = styled.img`
`
Element.modalHeaderRight = styled.div`
  display: flex;
  gap: 10px;
`
Element.modalProfile = styled.img`
  height: 38px;
  width: 38px;
`
Element.modalForm = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`
export default Element