import '../style/style.css'
import {ClerkProvider, SignIn} from "@clerk/clerk-react";

import { ReactComponent as RosieLogo } from '../assets/svg/rosie_vision_logo.svg';
import Element from '../style/screens/login_screen_styles'
import React from "react";

const PUBLISHABLE_KEY = 'pk_test_c3F1YXJlLWFuZW1vbmUtOTQuY2xlcmsuYWNjb3VudHMuZGV2JA'

const LoginScreen = () => {
  return (
    <div className='login'>
      <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <Element.header>
        <RosieLogo/>
        {/*
         */}
        <SignIn/>
      </Element.header>
      </ClerkProvider>
    </div>
  )
}

export default LoginScreen
