import React, {useEffect, useRef, useState} from 'react'
import Webcam from 'react-webcam'
import Modal from 'react-modal'
import compare from 'ssim.js'
import '../style/style.css'
import Images from '../assets/images/images'
import Element from '../style/screens/identify_screen_styles'

// import { currentUser } from '@clerk/nextjs/server'
import { getImageIdApi, postImageDataApi } from '../util/imagescan'
import { uploadImageAction } from '../util/uploadImageAction'

const videoConstraints = {
  facingMode: { exact: 'environment' },
  aspectRatio: 2.0
}

const FrameMatch = {match: 0, nomatch: 1}
const FormStatus = {idle: 0, submitting: 1}

const titles = {
  '1': 'Get started by identifying an item',
  '2': 'Scanning area for an item',
  '3': 'Item detected.  Hold camera steady.',
  '3a': 'Item detected.  Hold camera steady.',
  '4': 'Let\'s get some additional angles',
  '5': 'Let\'s Scan the barcode first',
  '6': 'Let\'s scan the product label'
}

const subtitles = {
  '1': 'Tap the icon below to begin analyzing your item.',
  '2': 'Make sure it\'s in focus and clear of obstacles.',
  '3': 'We\'re comparing this against our database for item identification.',
  '3a': 'We\'re comparing this against our database for item identification.',
  '4': 'Move your camera around so we can see a little more about your object.',
  '5': 'This will help us collect the most accurate information about your item',
  '6': 'Donec id elit non mi porta gravida at eget metus. Nullam quis risus eget urna.'
}



const IdentifyScreen = () => {
  const webcamRef = useRef(null)
  const [images, setImages] = useState([])
  const [isFrameMatching, setIsFrameMatching] = useState(FrameMatch.nomatch)
  const [formStatus, setFormStatus] = useState(FormStatus.idle)
  const [countdown, setCountDown] = useState(30)
  const [canCapture, setCanCapture] = useState(false)
  const [, setSsimResults] = useState([])
  const [, setImageContents] = useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [info, setInfo] = useState('info...')
  const [, setInfo2] = useState('')

  const [step, setStep] = useState('1')
  const [showFlash, setShowFlash] = useState(false)


  const handleSubmit = async () => {
    setInfo('submitting...')
    // const user = await currentUser()
    // const userid = user?.id as string
    const userid = 'user_2lw7GUGNhGX8t8nm6IJAuveYWw6'
    if (formStatus === FormStatus.submitting) {
      console.log('Already Submitting')
      return
    }
    setFormStatus(FormStatus.submitting)
    if (images.length < 1) {
      console.error('Image not Found')
      setFormStatus(FormStatus.idle)
      return
    }

    if (images && images.length > 0) {
      const randomid = 'abcde'

      images.map(async (img, index) => {
        const imgData = await loadImage(img)
        imageDataToFile(imgData, `${randomid}_${index}.png`).then(
          async file => {
            const base64File = await fileToBase64(file)
            const filename = `${randomid}_${index}.jpg`
            const imageUrl = await uploadImageAction(
              base64File,
              filename,
              userid
            )

            const imageDataUpload = await postImageDataApi({
              imageUrl,
              ownerId: userid,
            })
            const result = await getImageIdApi({
              assetId: imageDataUpload.data.image_feature_id,
            })
            // DISPLAY OBJECT in UI
            const content = {
              imageUrl: imageUrl,
              imageId: result.data.data.id,
              objectId: result.data.data.object,
            }
            setImageContents(prevUrls => [...prevUrls, content])
            // setModalIsOpen(true)
          }
        )
      })
    }
    setFormStatus(FormStatus.idle)
  }

  const capture = () => {
    setInfo('capturing image')
    if (isFrameMatching === FrameMatch.match) {
      setInfo('frame match')
      return
    }
    setInfo('frame different, ' + images.length + ' images')
    if (images.length < 5) {
      if (webcamRef.current) {
        const imageSrc = webcamRef.current.getScreenshot()
        setInfo('screenshot taken, ' + imageSrc )
        if (imageSrc) {
          setInfo('setting images')
          setImages(prevImages => [...prevImages, imageSrc])
        }
      }
    }
    else {
      handleSubmit()
    }
  }

  const handleRemoveImage = (index) => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index))
  }

  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result.toString())
        } else {
          reject(new Error('Error converting file to Base64'))
        }
      }
    })
  }

  function imageDataToFile(imageData, fileName) {
    return new Promise((resolve, reject) => {
      // Create a canvas to draw the ImageData
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      if (!ctx) {
        reject(new Error('Failed to create canvas context'))
        return
      }

      // Set canvas dimensions to match the ImageData dimensions
      canvas.width = imageData.width
      canvas.height = imageData.height

      // Draw the ImageData onto the canvas
      ctx.putImageData(imageData, 0, 0)

      // Convert the canvas data to a Blob
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Failed to convert ImageData to Blob'))
          return
        }

        // Convert Blob to File
        const file = new File([blob], fileName, { type: 'image/png' })
        resolve(file)
      }, 'image/png')
    })
  }

  const checkFrame = async () => {
    setInfo('in checkFrame(): ' + images.length + ' images')
    if (images.length > 0) {
      const currentImageSrc = webcamRef.current?.getScreenshot()
      if (currentImageSrc) {
        const currentImage = await loadImage(currentImageSrc)
        let isMatching = false
        const newSsimResults = []

        for (const imgSrc of images) {
          const img = await loadImage(imgSrc)
          const { mssim } = compare(currentImage, img)
          const percent = parseFloat(mssim.toFixed(3)) * 100
          // Store the SSIM result with the image source
          newSsimResults.push({
            imageSrc: imgSrc,
            result: parseFloat(percent.toFixed(2)),
          })
          if (mssim < 0.25 || mssim > 0.65) {
            isMatching = true
            setIsFrameMatching(FrameMatch.match) // Set frame matching status
            break
          }
        } // Set frame matching status if no match found

        setSsimResults(prevResults => {
          const updatedResultsMap = new Map(
            prevResults.map(result => [result.imageSrc, result.result])
          )
          newSsimResults.forEach(({ imageSrc, result }) => {
            updatedResultsMap.set(imageSrc, result)
          }) // Convert the map back to an array
          return Array.from(updatedResultsMap.entries()).map(
            ([imageSrc, result]) => ({ imageSrc, result })
          )
        })
        if (!isMatching) {
          setIsFrameMatching(FrameMatch.nomatch)
          capture()
        } // Update state by merging new results with existing results
      }
    } else {
      capture()
    }
  }


  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = src
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext('2d')
        if (ctx) {
          ctx.drawImage(img, 0, 0)
          const imageData = ctx.getImageData(0, 0, img.width, img.height)
          resolve(imageData)
        } else {
          reject(new Error('Could no get 2d context'))
        }
      }
      img.onerror = err => reject(err)
    })
  }

  useEffect(() => {
    if (!canCapture) {
      return
    }
    checkFrame()
    const intervalId = setInterval(() => {
      checkFrame()
    }, 5000)

    return () => clearInterval(intervalId)
  })

  const startCapturing = () => {
    setInfo('capturing...')
    setCanCapture(true)
    setCountDown(30)
  }

  const stopCapturing = () => {
    setInfo('idle')
    setCanCapture(false)
  }

  useEffect(() => {
    setInfo2(`canCapture: ${canCapture}`)
    let timer
    let countdowntimer
    if (canCapture) {
      countdowntimer = setInterval(() => {
        setCountDown(prev => prev - 1)
      }, 1000)
      timer = setTimeout(() => {
        setCanCapture(false)
        setCountDown(30)
      }, 30000)

      return () => {
        clearTimeout(timer)
        clearInterval(countdowntimer)
      }
    }
  }, [canCapture])

  const cssClass = (prefix) => prefix + step

  const dotImage = () => {
    if (step === '1') {
      return Images.dotsWhite
    }
    if (step === '2') {
      return Images.dotsBlue
    }
    if (step === '3') {
      return Images.dotsGreen
    }

  }

  const flash = () => {
    setShowFlash(true)
    setTimeout(() => setShowFlash(false), 50)
    startCapturing()
  }

  if(step === '2') {
    setTimeout(() => setStep('3'), 2000)
    setTimeout(() => flash(), 4000)
    setTimeout(() => setStep('3a'), 5000)
  }


  return (
    <>
      <Element>
        { (step !== '5' && step !== '6') ? (
        <div className='circle-overlay'>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat='image/jpeg'
            videoConstraints={videoConstraints}
            className='w-full h-full z-10'
          />
        </div>
          ) : (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat='image/jpeg'
            videoConstraints={videoConstraints}
            className='w-full h-full z-10'
          />
        )}

        { (step === '5' || step === '6') &&
          <div className='rect-overlay'></div>
        }

        <Element.topLayer>
        <div className='flash' hidden={showFlash === false}/>
          <Element.header>
            <Element.header.logo src={Images.rosieHeaderLogo}/>
            <Element.header.right>
              <Element.header.home src={Images.homeBlueBtn}/>
              <Element.header.profile src={Images.avatar}/>
            </Element.header.right>
          </Element.header>

          <div className={cssClass('i-title-')}>{titles[step]}</div>
          <div className={cssClass('i-subtitle-container-')}>
            <Element.subtitle>{subtitles[step]}</Element.subtitle>
          </div>

          <Element.rosieBtn
            src={Images.iconRoundColorWhiteBG}
            onClick={() => setStep('2')}
            hidden={step !== '1'}
          />

          <Element.rosieBarcodeBtn
            src={Images.iconRoundColorWhiteBG}
            onClick={() => setStep('2')}
            hidden={step !== '5' && step !== '6'}
          />

          <Element.progressContainer hidden={step !== '3'}>
            <Element.progressCircle src={Images.progress}/>
            <Element.progressCircleIndicator src={Images.progressIndicator}/>
          </Element.progressContainer>

          <Element.progressContainer hidden={step !== '3a'}>
            <Element.progressCircle src={Images.mockThumb}/>
          </Element.progressContainer>

          <Element.progressContainer hidden={step !== '4'}>
            <Element.progressCircle src={Images.angles}/>
            <Element.progressCircleIndicator src={Images.group}/>
            <Element.progressCircleRing src={Images.ring}/>
          </Element.progressContainer>

          <Element.dots src={dotImage()}/>

          <Element.footer>
            <img
              src={Images.cancel}
              onClick={() => setStep('1')}
              hidden={step === '1' || step === '6'}
            />
            <img
              src={Images.snap}
              hidden={step === '1' || step === '6'}
              onClick={() => {
                setStep('4')
                setModalIsOpen(true)
                // flash()
              }}
            />
          </Element.footer>
          <Element.console>
            <span>{info}</span>
            <span>{countdown}sec</span>
            <span>{canCapture.toString()}</span>
          </Element.console>
        </Element.topLayer>
      </Element>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel='Image Data'
        overlayClassName='i-modal-overlay'
        className='i-modal'
      >
        <Element.modalContent>
          <Element.form>
            <img src={Images.matchImage}/>
            <Element.modalTitle>Is This The Same Item?</Element.modalTitle>
            <Element.modalSubtitle>
              We think we may have found this
              already in your inventory.
            </Element.modalSubtitle>

            <Element.btnDark type='button'>
              <Element.btnContent>
                <span>Yes, it’s the same</span>
              </Element.btnContent>
            </Element.btnDark>
            <Element.btnLight type='button' onClick={() => setModalIsOpen(false)}>
              No, keep scanning
            </Element.btnLight>
          </Element.form>
        </Element.modalContent>
      </Modal>
    </>
  )
}

export default IdentifyScreen