import styled, { keyframes } from 'styled-components'
import { Colors } from '../../constants/colors'

const Element = styled.div`
  min-height: 100vh;
  padding: 20px;
`
Element.header = styled.div`
  display: flex;
  justify-content: space-between;
`
Element.header.logo = styled.img`
`
Element.header.right = styled.div`
  display: flex;
  gap: 10px;
`
Element.header.home = styled.img`
  height: 40px;
  width: 40px;
`
Element.header.profile = styled.img`
  height: 38px;
  width: 38px;
`
Element.form = styled.div`
  margin-top: 30px;
  padding: 5px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
Element.photo = styled.div`
  position: relative;
  margin-top: 20px;
`
Element.pic = styled.img`
  border-radius: 8px;
  padding-bottom: 5px;
`
Element.picEdit = styled.img`
  position: absolute;
  margin-top: -55px;
  margin-left: 285px;
  border-radius: 8px;
`
Element.input = styled.input`
  border-width: 1px;
  border-radius: 4px;
  width: 100%;
  padding-top: 8px;     
  padding-bottom: 8px;
  padding-left: 12px;     
  padding-right: 12px;
  color: ${Colors.grayText};
  line-height: 1.25;
`
Element.title = styled.div`
  margin-top: 10px;
  font-size: 24px;
  line-height: 39px;
  padding-right: 20px;
  color: #221F20;
`
Element.btnDark = styled.button`
  background: #483EE7;
  border-radius: 8px;
  color: white;
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 10px;
  width: 335px;
`

Element.btnContent = styled.div`
display: flex;
justify-content: center;
gap: 5px;
 `

Element.btnLight = styled.button`
  background: white;
  width: 335px;
  border: 1px solid #57BBF8;
  border-radius: 8px;
  color: #221F20;
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  `

export default Element